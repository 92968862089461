<template>
  <div :class="{ 'v-dialog--scrollable': scrollable }">
    <v-card elevation="0">
      <v-card-title class="d-flex align-center justify-space-between px-0 pt-0">
        <h6 class="text-overline font-weight-bold lh-1 text-18 mb-0">
          Carrinho
        </h6>
        <h6 class="font-weight-bold lh-1 text-16 mb-0">
          {{ totalValue.quantity }} ingresso{{
            totalValue.quantity > 1 ? "s" : ""
          }}
        </h6>
      </v-card-title>

      <v-card-text class="px-0">
        <div class="d-flex flex-column gap-3">
          <v-card
            v-for="(cartItem, i) in cartList"
            :key="i"
            outlined
            class="pa-3 rounded-lg mx-1"
          >
            <div class="d-flex align-center justify-space-between gap-2 mb-3">
              <p class="mb-0 lh-1 font-weight-mediu">
                {{ cartItem.ticketGroup.name }}
              </p>
              <p class="mb-0 lh-1 font-weight-medium text-end">
                {{ cartItem.ticketBlock.name }}
              </p>
            </div>

            <div class="d-flex justify-space-between align-center">
              <h6 class="mb-0 pr-2 font-weight-medium">
                {{ cartItem.quantity }}x
              </h6>
              <v-divider />
              <div>
                <h6 class="pl-3 text-end lh-1 font-weight-black mb-0">
                  <template v-if="cartItem.ticketBlock.canUseMembership">
                    <v-chip
                      :color="
                        cartItem.ticketBlock.membershipPrice[0].Membership
                          .backgroundColor
                      "
                      small
                      text-color="white"
                      label
                      class="font-weight-medium text-14 mr-3"
                      :style="{
                        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
                          cartItem.ticketBlock.membershipPrice[0].Membership
                            .backgroundImg
                        }), ${
                          cartItem.ticketBlock.membershipPrice[0].Membership
                            .backgroundColor || '#3333'
                        }80`,
                        backgroundSize: 'cover',
                        border: `1px solid ${
                          cartItem.ticketBlock.membershipPrice[0].Membership
                            .backgroundColor || '#000'
                        }`,
                        boxShadow: `0 0 10px  ${
                          cartItem.ticketBlock.membershipPrice[0].Membership
                            .backgroundColor || 'rgba(0,0,0,.2)'
                        }`,
                      }"
                    >
                      {{
                        cartItem.ticketBlock.membershipPrice[0].Membership.name
                      }}
                    </v-chip>
                    <span
                      class="text-decoration-line-through text-14 text-end mr-2 font-weight-light"
                    >
                      {{ cartItem.ticketBlock.price | currency(true) }}
                    </span>
                  </template>
                  {{
                    cartItem.ticketBlock.canUseMembership
                      ? cartItem.ticketBlock.membershipPrice[0].price
                      : cartItem.ticketBlock.price | currency(true)
                  }}
                </h6>
              </div>
            </div>
            <p
              v-if="cartItem.ticketBlock.fee"
              class="mb-0 text-end text-12 lh-1 text--secondary"
            >
              Taxa de serviço:
              <b class="font-weight-medium">
                +{{ cartItem.ticketBlock.fee | currency(true) }}
              </b>
            </p>
            <div
              v-if="cartItem.ticketBlock.canUseMembership"
              class="d-flex justify-space-between align-center mt-3"
            >
              <div>
                <span v-if="cartItem.biggestDiscount" class="text-12 mb-0 lh-2">
                  Maior desconto possível
                </span>
              </div>
              <span class="text-14 text-end mb-0 lh-1">
                Você economizou
                <b class="font-weight-medium">
                  {{
                    (cartItem.ticketBlock.price -
                      cartItem.ticketBlock.membershipPrice[0].price)
                      | currency(true)
                  }}
                </b>
              </span>
            </div>
            <v-alert
              v-if="cartItem.table"
              text
              dense
              type="primary"
              class="rounded-lg mb-0 mt-1"
            >
              <div class="d-flex align-center gap-2">
                <v-icon small color="primary">mdi-table-chair</v-icon>
                <p class="mb-0 font-weight-bold text-15">
                  {{ cartItem.table.group.name }} • {{ cartItem.table.name }}
                </p>
                <v-spacer />
                <v-chip color="primary" text-color="white" x-small>
                  {{ cartItem.table.group.capacity }} pessoa{{
                    cartItem.table.group.capacity > 1 ? "s" : ""
                  }}
                </v-chip>
              </div>
              <p class="mb-0 lh-2 text-14">
                Essa compra dá acesso a
                <b class="font-weight-bold">
                  {{ cartItem.table.group.capacity }} ingresso{{
                    cartItem.table.group.capacity > 1 ? "s" : ""
                  }}</b
                >, que podem ser transferidos após o pagamento.
              </p>
            </v-alert>
          </v-card>
        </div>
      </v-card-text>

      <v-card-actions class="d-flex flex-column align-stretch pa-0 pt-2 mt-2">
        <template v-if="totalValue.discount">
          <div
            class="d-flex align-center justify-space-between text-15 text--disabled"
          >
            <span class="mb-0 font-weight-medium">Subtotal</span>
            <span class="mb-0 font-weight-medium">
              {{ (totalValue.total + totalValue.discount) | currency(true) }}
            </span>
          </div>
          <div class="d-flex align-center justify-space-between text-15 mb-2">
            <span class="mb-0 font-weight-medium">Desconto</span>
            <span class="mb-0 font-weight-medium">
              {{ (totalValue.discount * -1) | currency(true) }}
            </span>
          </div>
        </template>
        <div
          class="d-flex align-center justify-space-between text-18 font-weight-black"
        >
          <span class="mb-0 lh-1">Total</span>
          <span class="mb-0 text-22 lh-1">
            {{ totalValue.total | currency(true) }}
          </span>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    cartList: {
      type: Array,
      default: () => [],
    },
    totalValue: {
      type: Object,
      default: () => ({
        quantity: 0,
        total: 0,
        discount: 0,
      }),
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
